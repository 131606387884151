<template>
  <div>

    <div class="form">
      <el-form :inline="true" label-width="90px" :model="form">
        <el-row>
          <el-col :span="7">
            <el-form-item label="产品名称" prop="productName">
              <el-input v-model="form.productName" clearable placeholder="请输入产品名称"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="产品类别" prop="productCategory">
              <el-select v-model="form.productCategory" clearable placeholder="请选择产品类别">
                <el-option v-for="item in $store.state.category"
                           :key="item.dictKey"
                           :label="item.dictValue" :value="item.dictKey"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="订单编号" prop="orderNo">
              <el-input v-model="form.orderNo" clearable placeholder="请输入订单编号"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="3">
            <el-form-item>
              <el-button type="primary" @click="onSubmit(form)">查询</el-button>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="订单状态" prop="status">
              <el-select v-model="form.status" clearable placeholder="请选择订单状态">
                <el-option v-for="item in $store.state.orderState"
                           :key="item.dictKey"
                           :label="item.dictValue" :value="item.dictKey"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>

    <div class="list" style="min-height: calc(100vh - 276px)">
      <el-table
          :data="table"
          style="width: 100%">
        <el-table-column
            prop="productName"
            align="center"
            label="产品名称">
          <template slot-scope="scope">
            <span class="blue" @click="deta(scope.row)" >{{ scope.row.productName }}</span>
          </template>
        </el-table-column>
        <el-table-column
            prop="actualPaymentAmount"
            align="center"
            label="实际支付价格">
        </el-table-column>
        <el-table-column
            prop="productCategory"
            align="center"
            label="产品类别">
        </el-table-column>
        <el-table-column
            prop="phoneNo"
            align="center"
            label="用户手机号">
        </el-table-column>
        <el-table-column
            prop="orderNo"
            align="center"
            label="订单编号">
        </el-table-column>
        <el-table-column
            prop="statusName"
            align="center"
            label="订单状态">
        </el-table-column>
        <el-table-column
            prop="createTime"
            align="center"
            label="下单时间">
        </el-table-column>
        <el-table-column
            fixed="right"
            align="center"
            label="操作">
          <template slot-scope="scope">
            <div class="operation">
              <el-popconfirm
                  v-if="scope.row.status === '1' && scope.row.actualPaymentAmount != null && scope.row.actualPaymentAmount != 0 "
                  title="此订单是否要申请退款？"
                  @confirm="refund(scope.row)"
              >
                <el-button slot="reference" type="text" size="small" >申请退款</el-button>
              </el-popconfirm>
<!--              <el-popconfirm-->
<!--                  title="是否要删除此订单？"-->
<!--                  @confirm="del(scope.row)"-->
<!--              >-->
<!--                <el-button slot="reference" type="text" size="small" >删除</el-button>-->
<!--              </el-popconfirm>-->
            </div>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="form.pageNum"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="form.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
      </el-pagination>
    </div>


<!--    <el-button size="small" style="margin: 10px 0" @click="videoTime">video_currentTime</el-button>
    <vue-core-video-player :autoplay="false" controls id="video" src="https://media.vued.vanthink.cn/sparkle_your_name_am720p.mp4"></vue-core-video-player>-->
  </div>
</template>

<script>
export default {
  name: "Order",
  data() {
    return {
      video: null,
      form: {
        pageNum: 1,
        pageSize: 10,
        orderNo: null, // 订单编号
        productCategory: null, // 产品类别
        productName: null, // 产品名称
        status: null, // 订单状态
      },
      table: [],
      total: 0,
    }
  },
  created() {
    this.get_list(this.form);
  },
  mounted() {

  },
  methods: {
    refund(y) {

      let obj= {};
      obj["id"]= y.id;
      obj["status"]= 3;

      this.$put("orderManage/edit",obj)
        .then(res => {
          if(res) {

            this.$message.success(res.message);
            this.form.pageNum= 1;
            this.get_list(this.form);

          }
        })

    },
    onSubmit(y) {
      y.pageNum= 1;
      this.get_list(y);
    },
    get_list(y) {
      this.$get("orderManage/page",y)
        .then(res => {
          if(res) {
            console.log(res);

            this.total= res.data.total;
            this.table= res.data.list;

          }
        })
    },
    del(y) {
      console.log(y);
      this.$del("orderManage",y.id,true)
        .then(res => {

          if(res) {

            this.$message.success(res.message);
            this.form.pageNum= 1;
            this.get_list(this.form);

          }

        })
    },
    handleSizeChange(e) {
      // console.log(e);
      // this.form.pageNum= 1;
      this.form.pageSize= e;
      this.get_list(this.form);
    },
    handleCurrentChange(e) {
      // console.log(e);
      this.form.pageNum= e;
      this.get_list(this.form);
    },
    deta(y) {
      this.$router.push({
        path: "/OrderDeta",
        query: {
          id: y.id
        }
      })
    },
    videoTime() {

      this.$nextTick(() => {
        console.log(document.getElementById("video").getElementsByTagName("video")[0])
      })

      document.getElementById("video").getElementsByTagName("video")[0].currentTime= 10;

    }
  }
}
</script>

<style scoped>
@import "./../../../node_modules/videojs-markers/dist/videojs.markers.min.css";
.operation{
  justify-content: center;
}
</style>
